import React from 'react'
import { Link } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import { ChevronRightIcon } from '@toasttab/buffet-pui-icons'

import BuildList from '../components/BuildList'
import { BuildNowButton } from '../components/BuildButtons'
import { Button } from '@toasttab/buffet-pui-buttons'

const BuildsPage = ({ username }) => {
  const { branch, repoName } = useParams()
  const [refetch, setRefetchBuilds] = React.useState(false)

  return (
    <>
      {branch ? (
        <div className='flex flex-col items-end gap-4 mx-4 mt-2 mb-4 md:mx-0 md:flex-row md:justify-between md:items-center'>
          <div className='font-semibold type-large flex items-center'>
            {
              <Button
                as={Link}
                variant='text-link'
                to={`/${repoName}/builds`}
                className='!type-large'
              >
                All branches
              </Button>
            }
            <span>
              <ChevronRightIcon className='text-secondary mx-1' />
            </span>
            {decodeURIComponent(branch)}
          </div>
          <BuildNowButton
            repoName={repoName}
            branch={decodeURIComponent(branch)}
            username={username}
            setRefetchBuilds={setRefetchBuilds}
          />
        </div>
      ) : (
        <div className='md:h-4' />
      )}

      <BuildList
        branch={branch}
        repoName={repoName}
        refetchBuilds={refetch}
        setRefetchBuilds={setRefetchBuilds}
      />
    </>
  )
}

export default BuildsPage
