import React from 'react'
import {
  CheckboxField,
  NumberInputField,
  SelectField
} from '@toasttab/buffet-pui-forms'

export const ImageToDeployField = ({ imageUris }) => (
  <SelectField
    options={imageUris}
    name='imageToDeploy'
    label='Image to Deploy'
  />
)

export const ShouldActivateDeployField = () => (
  <CheckboxField label='Activate?' name='activateDeploy' />
)

export const TimeToLiveField = () => (
  <NumberInputField
    label='Days to Persist'
    inputMode='numeric'
    name='timeToLive'
    max='14'
    min='1'
    helperText='This can be between 1 and 14 days'
    decimalScale='0'
  />
)
