import { singleSpaCssLifecycles } from '@toasttab/banquet-single-spa-css'
import { banquetSingleSpaReact } from 'banquet-runtime-modules'
import App from '../packages/BuildPage/pages/App'

import './index.css'

const reactLifecycles = banquetSingleSpaReact({
  singleSpaCssLifecycles,
  cssScope: 'data-tdp-build',
  // @ts-ignore
  rootComponent: App
})

export const bootstrap = reactLifecycles.bootstrap
export const mount = reactLifecycles.mount
export const unmount = reactLifecycles.unmount
