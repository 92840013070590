import * as React from 'react'
import {
  PortalManagement,
  Overlay,
  useFloating
} from '@toasttab/buffet-pui-floating-ui-base'

export interface SimpleFullScreenModalProps {
  children: React.ReactNode
  onRequestClose?: () => undefined
}

export const SimpleFullScreenModal = ({
  children,
  onRequestClose
}: SimpleFullScreenModalProps) => {
  React.useEffect(() => {
    const onKeyUp = (event: KeyboardEvent) => {
      if (event.code === 'Escape' && onRequestClose) {
        onRequestClose()
      }
    }

    document.addEventListener('keyup', onKeyUp)

    return () => document.removeEventListener('keyup', onKeyUp)
  }, [onRequestClose])

  const { context, refs } = useFloating()
  return (
    <PortalManagement context={context} isModal>
      <Overlay lockScroll className='z-40 bg-white'>
        <div ref={refs.setFloating}>{children}</div>
      </Overlay>
    </PortalManagement>
  )
}
