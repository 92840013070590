import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { BuildListTable } from './BuildListTable'

import { MerryGoRound } from '@toasttab/buffet-pui-loading-indicators'

import { fetchBuilds } from '../queries'
import { usePrevious } from '../hooks'

const BuildList = ({ branch, repoName, refetchBuilds, setRefetchBuilds }) => {
  const [previousKeys, setPreviousKeys] = useState([])
  const [currentKey, setCurrentKey] = useState()
  const previousBuild = usePrevious(branch)

  useEffect(() => {
    setPreviousKeys([])
    setCurrentKey(null)
    setRefetchBuilds(false)
  }, [branch, repoName, refetchBuilds, setRefetchBuilds])

  const { data, error, isLoading, refetch } = useQuery(
    [
      'fetchBuilds',
      {
        branch,
        repoName,
        lastKey: previousBuild !== branch ? null : currentKey
      }
    ],
    fetchBuilds
  )

  if (isLoading) {
    return (
      <div className='relative h-60'>
        <div className='pin-center'>
          <MerryGoRound />
        </div>
      </div>
    )
  }

  if (error) {
    return <p>An error happened: {JSON.stringify(error)}</p>
  }

  if (refetchBuilds) {
    refetch().catch((error) => console.log(error))
  }

  const nextKey = data.lastKey ? JSON.stringify(data.lastKey) : null

  const handleClickFirst = () => {
    setCurrentKey(null)
    setPreviousKeys([])
  }

  const handleClickNext = () => {
    previousKeys.push(currentKey)
    setPreviousKeys(previousKeys)

    setCurrentKey(nextKey)
  }

  const handleClickPrevious = () => {
    setCurrentKey(previousKeys.pop())
    setPreviousKeys(previousKeys)
  }

  return (
    <BuildListTable
      data={data}
      isLoading={isLoading}
      error={error}
      hasNextPages={nextKey}
      hasPreviousPages={previousKeys.length > 0}
      handleClickFirst={handleClickFirst}
      handleClickNext={handleClickNext}
      handleClickPrevious={handleClickPrevious}
    />
  )
}

export default BuildList
