import React from 'react'
import cx from 'classnames'
import {
  AutorenewIcon,
  CheckCircleSelectedFillIcon,
  DocumentIcon,
  WarningOutlineIcon
} from '@toasttab/buffet-pui-icons'
import { capitalize } from './capitalize'

const BUILD_STATUSES_SM = {
  FAILED: {
    className: 'text-error',
    icon: <WarningOutlineIcon />,
    text: 'Failed'
  },
  PASSED: {
    className: 'text-success',
    icon: <CheckCircleSelectedFillIcon />,
    text: 'Passed'
  },
  NO_YAML: {
    icon: <DocumentIcon className='text-secondary' />,
    text: 'No build yml'
  },
  CANT_PARSE_YAML: {
    icon: <DocumentIcon className='text-secondary' />,
    text: 'Cannot parse build yml'
  },
  SYNTAX_ERROR: {
    icon: <DocumentIcon className='text-secondary' />,
    text: 'Syntax error in Jenkinsfile'
  },
  GIT_ERROR: {
    icon: <DocumentIcon className='text-secondary' />,
    text: 'Error in git checkout'
  },
  ERROR: {
    icon: <WarningOutlineIcon className='text-secondary' />,
    text: 'Unexpected error'
  },
  RUNNING: {
    icon: <AutorenewIcon className='text-secondary animate-spin' />,
    text: 'Running'
  }
}
const BUILD_STATUSES = {
  CREATED: {
    icon: (
      <AutorenewIcon
        className='text-secondary animate-spin'
        aria-label='In progress'
      />
    )
  },
  QUEUED: {
    icon: (
      <AutorenewIcon
        className='text-secondary animate-spin'
        aria-label='In progress'
      />
    )
  },
  ...BUILD_STATUSES_SM
}

export const BuildStatusSmall = ({ status }) => {
  let className = 'text-default'
  let icon = undefined
  let text = status.toLowerCase()

  const buildStatus = BUILD_STATUSES_SM[status]
  if (buildStatus) {
    className = buildStatus.className || className
    icon = buildStatus.icon
    text = capitalize(buildStatus.text || text)
  }

  return (
    <span className={cx('space-x-0.5', 'type-caption', className)}>
      {icon && React.cloneElement(icon, { size: 'xs' })}
      <span>{text}</span>
    </span>
  )
}

export const BuildStatus = ({ status }) => {
  let className = 'text-default'
  let icon = undefined
  let text = status.toLowerCase()

  const buildStatus = BUILD_STATUSES[status]
  if (buildStatus) {
    className = buildStatus.className || className
    icon = buildStatus.icon
    text = capitalize(buildStatus.text || text)
  }

  return (
    <span
      className={cx('gap-1', 'type-default', 'flex items-start', className)}
    >
      {icon && React.cloneElement(icon, { size: 'sm' })}
      {text}
    </span>
  )
}
