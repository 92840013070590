import React from 'react'
import { getBuildsData } from '../queries'
import { useQuery } from 'react-query'
import { BuildListTable } from '../components/BuildListTable'

const AllBuildsPage = () => {
  const { query, config } = {
    query: getBuildsData,
    config: {
      retry: (_, error) => {
        if (error && error.status === 403) {
          return false
        }
        return true
      }
    }
  }
  const { data, error, isLoading } = useQuery(undefined, query, config)

  return (
    !error &&
    !isLoading && (
      <BuildListTable
        data={{
          builds: data.sort((a, b) => {
            return b.timestamp - a.timestamp
          })
        }}
        isLoading={isLoading}
        error={error}
        isGlobalList={true}
      />
    )
  )
}
export default AllBuildsPage
