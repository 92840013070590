import * as React from 'react'
import cx from 'classnames'
import { IconButton } from '@toasttab/buffet-pui-buttons'
import { ExpandMoreIcon } from '@toasttab/buffet-pui-icons'

export interface CommitMsgProps {
  spaId: string
  commitMsg: string
  gitHubHost: string
}

export const CommitMsg = ({ spaId, commitMsg, gitHubHost }: CommitMsgProps) => {
  const lines = commitMsg
    .split('\n')
    .map((line) => line.trim())
    .filter((line) => line!!)

  const subject = lines[0]
  const rest = lines.slice(1)

  const [isExpanded, setExpanded] = React.useState<boolean>(false)
  return (
    <div>
      <div>
        <SubjectWithPullRequestLink
          spaId={spaId}
          subject={subject}
          gitHubHost={gitHubHost}
        />
        {rest.length > 0 && (
          <span className='px-2'>
            <IconButton
              aria-label={
                isExpanded
                  ? 'Hide commit message details'
                  : 'Show commit message details'
              }
              cropToIcon
              icon={
                <ExpandMoreIcon
                  className={cx(
                    'text-secondary transform transition-transform',
                    {
                      'rotate-180': isExpanded
                    }
                  )}
                />
              }
              onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                e.preventDefault()
                setExpanded(!isExpanded)
              }}
            />
          </span>
        )}
      </div>
      {isExpanded && (
        <div>
          {rest.map((line, index) => (
            <div key={index} className='text-secondary'>
              {line}
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

const SubjectWithPullRequestLink = ({
  spaId,
  subject,
  gitHubHost
}: {
  spaId: string
  subject: string
  gitHubHost: string
}) => {
  const commitMsgMatch = subject?.match(/^(.*)\s\(#([0-9]*)\)$/)
  if (commitMsgMatch && commitMsgMatch.length > 2) {
    const pullRequestNumber = commitMsgMatch[2]
    return (
      <>
        {commitMsgMatch[1]} (
        {createPullRequestLink(spaId, pullRequestNumber, gitHubHost)})
      </>
    )
  }
  const commitMsgMatch2 = subject?.match(
    /^Merge pull request #([0-9]*) from (.*)$/
  )
  if (commitMsgMatch2) {
    const pullRequestNumber = commitMsgMatch2[1]
    return (
      <>
        Merge pull request{' '}
        {createPullRequestLink(spaId, pullRequestNumber, gitHubHost)} from{' '}
        {commitMsgMatch2[2]}
      </>
    )
  }
  return <>{subject}</>
}

const createPullRequestLink = (
  spaId: string,
  pullRequestNumber: string,
  gitHubHost: string
) => (
  <a
    className='text-link hover:underline'
    href={`https://${gitHubHost}/toasttab/${spaId}/pull/${pullRequestNumber}`}
    target='_blank'
    rel='noreferrer'
  >
    #{pullRequestNumber}
  </a>
)
