import React, { useState } from 'react'

import { Button } from '@toasttab/buffet-pui-buttons'
import { Alert } from '@toasttab/buffet-pui-alerts'

import { checkResponse, triggerJob } from '../queries'
import { MerryGoRound } from '@toasttab/buffet-pui-loading-indicators'

const triggerBuild = (
  { repoName, branch, username },
  setBuilding,
  setIsShowing,
  setRefetchBuilds,
  setIsError
) => {
  setBuilding(true)
  let body = JSON.stringify({
    repository_name: repoName,
    branch: branch,
    username: username
  })
  triggerJob(body)
    .then(checkResponse)
    .catch((error) => {
      setIsShowing(true)
      setIsError(true)
      console.log(error.message)
    })
    .then(() => {
      setBuilding(false)
      setIsShowing(true)
    })
    .then(() => setRefetchBuilds(true))
}

export const BuildNowButton = ({
  repoName,
  branch,
  username,
  setRefetchBuilds
}) => {
  const [building, setBuilding] = useState(false)
  const [isShowing, setIsShowing] = useState(false)
  const [isError, setIsError] = useState(false)
  if (building) {
    return <MerryGoRound />
  }
  return (
    <>
      {isShowing ? (
        <Alert variant={isError ? 'error' : 'success'} showIcon>
          {isError ? `Could not build ${branch}.` : `Building ${branch}...`}
        </Alert>
      ) : (
        <Button
          onClick={() => {
            triggerBuild(
              { repoName, branch, username },
              setBuilding,
              setIsShowing,
              setRefetchBuilds,
              setIsError
            )
          }}
        >
          Build now
        </Button>
      )}
    </>
  )
}
