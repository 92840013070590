import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import BuildDetailsPage from './BuildDetailsPage'
import BuildsPage from './BuildsPage'
import AllBuildsPage from './AllBuildsPage'

import React from 'react'
import LegacyBuildRedirect from '../components/LegacyBuildRedirect'

const App = ({ username }) => {
  const basename = window.location.pathname.startsWith('/node')
    ? '/node'
    : undefined

  return (
    <Router basename={basename}>
      <Switch>
        <Route exact path={['/bob-internal/builds']}>
          <AllBuildsPage />
        </Route>
        <Route exact path={['/:repoName/builds/:timestamp,:buildId']}>
          <LegacyBuildRedirect></LegacyBuildRedirect>
        </Route>
        <Route exact path={['/:repoName/builds/:buildId']}>
          <BuildDetailsPage />
        </Route>

        <Route
          path={['/:repoName/builds/branches/:branch', '/:repoName/builds/']}
        >
          <BuildsPage username={username} />
        </Route>
      </Switch>
    </Router>
  )
}

export default App
