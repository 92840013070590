import DateTime from 'luxon/src/datetime'
import React from 'react'

import { Button } from '@toasttab/buffet-pui-buttons'
import { CardContainer } from '@toasttab/buffet-pui-card'
import { MerryGoRound } from '@toasttab/buffet-pui-loading-indicators'
import {
  Body,
  Cell,
  Head,
  HeadingCell,
  Row,
  Table
} from '@toasttab/buffet-pui-table'

import { BuildStatus } from './BuildStatus'
import { Link } from 'react-router-dom'

const LinkToBranchBuilds = ({ branch, repoName }) => (
  <Link to={`/${repoName}/builds/branches/${encodeURIComponent(branch)}`}>
    {branch}
  </Link>
)

const LinkToBuild = ({ build }) => (
  <Link to={`/${build.repository}/builds/${build.id}`}>
    {DateTime.fromMillis(build.timestamp).toFormat('yyyy/MM/dd HH:mm:ss')}
  </Link>
)

const LinkToPullRequest = ({ pullRequestUrl }) => (
  <a
    href={pullRequestUrl}
    title={'View ' + pullRequestUrl + ' in a new tab.'}
    target='_blank'
    className='flex gap-1'
    rel='noreferrer noopener'
  >
    {'PR-' + pullRequestUrl.split('/').pop()}
  </a>
)

const fitCommitMessage = (message) =>
  message.length <= 80 ? message : `${message.substr(0, 80)}...`

export const BuildListTable = ({
  data,
  isLoading,
  error,
  hasNextPages,
  hasPreviousPages,
  handleClickNext,
  handleClickPrevious,
  handleClickFirst,
  isGlobalList = false
}) => {
  if (isLoading) {
    return (
      <div className='relative h-60'>
        <div className='pin-center'>
          <MerryGoRound />
        </div>
      </div>
    )
  }

  if (error) {
    return <p>An error happened: {JSON.stringify(error)}</p>
  }

  const toRow = (build) => (
    <Row key={build.id}>
      {isGlobalList ? (
        <Cell>
          <Link to={`/${build.repository}/builds/}`}>{build.repository}</Link>
        </Cell>
      ) : (
        <></>
      )}
      <Cell>
        <LinkToBuild build={build} />
      </Cell>
      <Cell className='hidden md:table-cell'>{build.commit.substr(0, 7)}</Cell>
      <Cell>
        <p>{fitCommitMessage(build.commitMessage)}</p>
      </Cell>
      <Cell>
        <LinkToBranchBuilds branch={build.branch} repoName={build.repository} />
      </Cell>
      <Cell className='hidden sm:table-cell'>
        {build.pullRequestUrl && (
          <LinkToPullRequest pullRequestUrl={build.pullRequestUrl} />
        )}
      </Cell>
      <Cell>
        <BuildStatus status={build.status} />
      </Cell>
      <Cell className='hidden sm:table-cell'>{build.buildVersion || ''}</Cell>
    </Row>
  )

  return (
    <CardContainer noPadding className='overflow-auto'>
      <Table>
        <Head>
          <Row>
            {isGlobalList ? <HeadingCell>Repository Name</HeadingCell> : <></>}

            <HeadingCell>Created at</HeadingCell>
            <HeadingCell className='hidden md:table-cell'>Commit</HeadingCell>
            <HeadingCell>Commit message</HeadingCell>
            <HeadingCell>Branch</HeadingCell>
            <HeadingCell className='hidden sm:table-cell'>
              Pull request
            </HeadingCell>
            <HeadingCell>Status</HeadingCell>
            <HeadingCell className='hidden sm:table-cell'>
              Build version
            </HeadingCell>
          </Row>
        </Head>
        <Body>{data.builds.map(toRow)}</Body>
      </Table>

      {(hasNextPages || hasPreviousPages) && (
        <div className='flex p-2.5 text-center gap-4 md:gap-2'>
          {hasPreviousPages && (
            <Button
              size='lg'
              type='button'
              variant='primary'
              onClick={() => handleClickFirst()}
            >
              First
            </Button>
          )}
          {hasPreviousPages && (
            <Button
              size='lg'
              type='button'
              variant='primary'
              onClick={() => handleClickPrevious()}
            >
              Previous
            </Button>
          )}
          {hasNextPages && (
            <Button
              size='lg'
              type='button'
              variant='primary'
              onClick={() => handleClickNext()}
            >
              Next
            </Button>
          )}
        </div>
      )}
    </CardContainer>
  )
}
