import React from 'react'

import {
  Body,
  Cell,
  Head,
  HeadingCell,
  Row,
  Table
} from '@toasttab/buffet-pui-table'
import { CardContainer } from '@toasttab/buffet-pui-card'
import { Link } from 'react-router-dom'

const RelatedBuilds = ({ build }) => {
  return (
    <>
      <CardContainer>
        <h3>This build triggers</h3>

        <Table>
          <Head>
            <HeadingCell>System</HeadingCell>
            <HeadingCell>Reference</HeadingCell>
          </Head>
          <Body>
            {build.buildsTriggered.map((b) => {
              return (
                <Row>
                  <Cell>{b.triggeredSystem}</Cell>
                  <Cell>
                    {b.triggeredSystemDeepLink.length > 0 ? (
                      <>
                        <Link to={'/' + b.triggeredSystemDeepLink}>
                          {b.triggeredSystemLinkText}
                        </Link>
                      </>
                    ) : (
                      <>{b.triggeredSystemLinkText}</>
                    )}
                  </Cell>
                </Row>
              )
            })}
          </Body>
        </Table>
      </CardContainer>
    </>
  )
}

export default RelatedBuilds
