import React from 'react'
import cx from 'classnames'
import { RadioGroupField, TextInputField } from '@toasttab/buffet-pui-forms'

const BaseConfigField = (props) => (
  <TextInputField
    {...props}
    containerClassName={cx('md:w-3/4', props.containerClassName)}
  />
)

export const DeployTypeField = () => (
  <RadioGroupField
    label='What would you like to deploy?'
    name='deployType'
    options={[
      { value: 'all', label: 'All' },
      { value: 'web', label: 'Toastweb' },
      { value: 'nonweb', label: 'All workers' },
      { value: 'worker', label: 'Single worker' }
    ]}
  />
)

export const DeploymentNumberField = () => (
  <BaseConfigField
    name='deploymentNumber'
    label='Deployment Number'
  ></BaseConfigField>
)

export const WorkerNameField = ({ isDisabled }) => (
  <BaseConfigField
    name='workerName'
    label='Worker Name'
    helperText='The name of the worker to deploy'
    disabled={isDisabled}
  ></BaseConfigField>
)
