import React from 'react'
import cx from 'classnames'
import {
  AutorenewIcon,
  CheckCircleSelectedFillIcon,
  WarningOutlineIcon
} from '@toasttab/buffet-pui-icons'
import { capitalize } from './capitalize'

const DEPLOYMENT_STATUSES_SM = {
  RUNNING: {
    icon: <AutorenewIcon className='text-secondary animate-spin' />,
    text: 'Running'
  },
  DEPLOYED: {
    className: 'text-success',
    icon: <CheckCircleSelectedFillIcon />,
    text: 'Deployed'
  },
  FAILED: {
    className: 'text-error',
    icon: <WarningOutlineIcon />,
    text: 'Deployment failed'
  }
}

const DEPLOYMENT_STATUSES = {
  CREATED: {
    icon: <AutorenewIcon className='text-secondary animate-spin' />
  },
  QUEUED: {
    icon: <AutorenewIcon className='text-secondary animate-spin' />
  },
  ...DEPLOYMENT_STATUSES_SM
}

export const DeploymentStatusSmall = ({ status }) => {
  let className = 'text-default'
  let icon = undefined
  let text = status.toLowerCase()

  const deploymentStatus = DEPLOYMENT_STATUSES_SM[status]
  if (deploymentStatus) {
    className = deploymentStatus.className || className
    icon = deploymentStatus.icon
    text = capitalize(deploymentStatus.text || text)
  }

  return (
    <span className={cx('space-x-0.5', 'type-caption', className)}>
      {icon && React.cloneElement(icon, { size: 'xs' })}
      <span>{text}</span>
    </span>
  )
}

export const DeploymentStatus = ({ status }) => {
  let className = 'text-default'
  let icon = undefined
  let text = status.toLowerCase()

  const deploymentStatus = DEPLOYMENT_STATUSES[status]
  if (deploymentStatus) {
    className = deploymentStatus.className || className
    icon = deploymentStatus.icon
    text = capitalize(deploymentStatus.text || text)
  }

  return (
    <span
      className={cx('gap-1', 'type-default', 'flex items-start', className)}
    >
      {icon && React.cloneElement(icon, { size: 'sm' })}
      {text}
    </span>
  )
}
