import React, { useState } from 'react'

import { Button } from '@toasttab/buffet-pui-buttons'
import { MerryGoRound } from '@toasttab/buffet-pui-loading-indicators'

import {
  downloadDeploymentLogs,
  downloadLogs,
  downloadTestReports
} from '../queries'

const downloadFile = (content, mimeType, name) => {
  const blob = new Blob([content], { type: mimeType })

  const link = document.createElement('a')
  document.body.appendChild(link)

  link.href = URL.createObjectURL(blob)
  link.setAttribute('type', 'hidden')
  link.setAttribute('download', name)
  link.click()

  document.body.removeChild(link)
}

const handleDownloadLogs = ({ id, repository }, setDownloading) => {
  setDownloading(true)
  downloadLogs({ buildId: id, repository })
    .then((content) => downloadFile(content, 'text/plain', `${id}-logs.txt`))
    .then(() => setDownloading(false))
}

const handleDownloadDeploymentLogs = ({ id, repository }, setDownloading) => {
  setDownloading(true)
  downloadDeploymentLogs({ buildId: id, repository })
    .then((content) =>
      downloadFile(content, 'text/plain', `${id}-deployment-logs.txt`)
    )
    .then(() => setDownloading(false))
}

const handleDownloadTestReports = ({ id, repository }, setDownloading) => {
  setDownloading(true)
  downloadTestReports({ buildId: id, repository })
    .then((content, mimeType) =>
      downloadFile(content, mimeType, `${id}-test-reports.zip`)
    )
    .then(() => setDownloading(false))
}

export const DownloadLogsButton = ({ build }) => {
  const [downloading, setDownloading] = useState(false)
  if (downloading) {
    return <MerryGoRound />
  }

  return (
    <Button onClick={() => handleDownloadLogs(build, setDownloading)}>
      Download log
    </Button>
  )
}

export const DownloadDeploymentLogsButton = ({ build }) => {
  const [downloading, setDownloading] = useState(false)
  if (downloading) {
    return <MerryGoRound />
  }

  return (
    <Button onClick={() => handleDownloadDeploymentLogs(build, setDownloading)}>
      Download deployment logs
    </Button>
  )
}

export const DownloadTestReportsButton = ({ build }) => {
  const [downloading, setDownloading] = useState(false)
  if (downloading) {
    return <MerryGoRound />
  }

  return (
    <Button onClick={() => handleDownloadTestReports(build, setDownloading)}>
      Download test reports
    </Button>
  )
}
