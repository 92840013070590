import React, { useState } from 'react'
import { useQuery } from 'react-query'
import { FixedSizeList } from 'react-window'
import AutoSizer from 'react-virtualized-auto-sizer'
import cx from 'classnames'

import { MerryGoRound } from '@toasttab/buffet-pui-loading-indicators'

import { IconButton } from '@toasttab/buffet-pui-buttons'

import { fetchDeploymentLogs } from '../queries'
import { DownloadDeploymentLogsButton } from './DownloadButtons'
import { CardContainer } from '@toasttab/buffet-pui-card'
import { CloseIcon, FullScreenIcon } from '@toasttab/buffet-pui-icons'
import { SimpleFullScreenModal } from './SimpleFullScreenModal'

const Convert = require('ansi-to-html')
const ansi = new Convert({
  escapeXML: true,
  stream: true,
  bg: '#fff',
  fg: 'rgb(37, 37, 37)'
})

const newLineRegex = /\r\n|\n|\r/

const DeploymentLogsPanel = ({ build, repoistoryId }) => {
  const [fetchInterval, setFetchInterval] = useState(2000)
  const containerRef = React.useRef()
  const [follow, setFollow] = React.useState(true)
  const [isFullscreen, setFullscreen] = React.useState(false)

  React.useLayoutEffect(() => {
    if (containerRef.current && follow) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight
    }
  })

  const { id: buildId, repository: repoName } = build
  const { queryId, query, config } = {
    queryId: ['fetchDeploymentLogs', { repoName, buildId }],
    query: fetchDeploymentLogs,
    config: {
      retry: (_, error) => {
        if (error && error.status === 404) {
          setFetchInterval(false)
          return false
        }
        return true
      }
    }
  }

  const onRequestClose = React.useCallback(() => {
    setFullscreen(false)
  }, [setFullscreen])

  const { data, error, isLoading } = useQuery(queryId, query, config)

  if (error && error.status === 404) {
    return (
      <p>Something is not right. We don't seem to have this log anymore.</p>
    )
  }

  if (error) {
    return <p>Error while fetching logs: {JSON.stringify(error)}</p>
  }

  const lines = (data && data.split(newLineRegex)) || []

  const DeploymentLogs = () => {
    return (
      <>
        <pre
          className={cx('overflow-auto outline-none', {
            'w-screen': isFullscreen
          })}
          re={containerRef}
          style={{
            height: isFullscreen ? '100vh' : '50vh',
            maxHeight: isFullscreen ? '100vh' : '480px'
          }}
        >
          {lines.length === 0 ? (
            <></>
          ) : lines.length < 10000 ? (
            <div className='p-4'>
              {lines.map((line, index) => {
                return (
                  <div
                    key={index}
                    // We are trusting ansi-to-html's escapeXML flag to work securely
                    dangerouslySetInnerHTML={{ __html: ansi.toHtml(line) }}
                  />
                )
              })}
            </div>
          ) : (
            <AutoSizer>
              {({ height, width }) => (
                <div className='pt-4 h-full'>
                  <FixedSizeList
                    height={height - 16}
                    itemCount={lines.length}
                    itemSize={24}
                    width={width}
                    initialScrollOffset={lines.length * 24 - height - 16}
                  >
                    {({ index, style }) => (
                      <div
                        className='px-4'
                        style={style}
                        // We are trusting ansi-to-html's escapeXML flag to work securely
                        dangerouslySetInnerHTML={{
                          __html: ansi.toHtml(lines[index])
                        }}
                      />
                    )}
                  </FixedSizeList>
                </div>
              )}
            </AutoSizer>
          )}
          {isLoading && (
            <div className={cx('p-4', { 'pt-0': lines.length > 0 })}>
              <MerryGoRound />
            </div>
          )}
        </pre>
        <div className='absolute top-1 right-1 rounded-full md:top-2 md:right-2 bg-white'>
          {!isFullscreen && (
            <IconButton
              icon={<FullScreenIcon />}
              onClick={() => setFullscreen(true)}
            />
          )}
          {isFullscreen && (
            <IconButton
              icon={<CloseIcon />}
              onClick={() => setFullscreen(false)}
            />
          )}
        </div>
      </>
    )
  }

  return (
    <>
      {isFullscreen ? (
        <SimpleFullScreenModal onRequestClose={onRequestClose}>
          <DeploymentLogs />
        </SimpleFullScreenModal>
      ) : (
        <CardContainer
          className='w-auto mb-4 relative'
          style={{
            minHeight: '48px'
          }}
          noPadding
        >
          <DeploymentLogs />
        </CardContainer>
      )}
      {error ||
        (build.deployFinishedAt ? (
          <DownloadDeploymentLogsButton build={build} />
        ) : (
          !isLoading && <p>No logs for this build.</p>
        ))}
    </>
  )
}

export default DeploymentLogsPanel
