import React from 'react'
import { Formik, Form } from 'formik'

import {
  ImageToDeployField,
  ShouldActivateDeployField,
  TimeToLiveField
} from './AdhocDeployFormFields'
import { validationSchema } from './AdhocDeployFormValidationSchema'
import { Modal } from '@toasttab/buffet-pui-modal'
import { Button } from '@toasttab/buffet-pui-buttons'

export const AdhocDeployForm = ({
  isDeployFormOpen,
  imageUris,
  activate,
  isAdhoc,
  deployFunction,
  close
}) => {
  const submitDeploy = (values) => {
    close()
    console.log(values)
    deployFunction(
      values.imageToDeploy,
      values.activateDeploy,
      values.timeToLive,
      values.serviceName
    )
  }

  return (
    <Modal isOpen={isDeployFormOpen}>
      {isAdhoc ? (
        <Modal.Header>Deploy Adhoc</Modal.Header>
      ) : (
        <Modal.Header>Deploy</Modal.Header>
      )}
      <Formik
        initialValues={{
          imageToDeploy: imageUris[0],
          activateDeploy: activate,
          timeToLive: 1
        }}
        onSubmit={submitDeploy}
        validationSchema={validationSchema}
      >
        {(props) => (
          <Form id='deploy-adhoc' autoComplete='false'>
            <Modal.Body>
              <div className='flex flex-col space-y-4 pb-1'>
                <ImageToDeployField imageUris={imageUris} />
                {isAdhoc ? <TimeToLiveField /> : <></>}
                <ShouldActivateDeployField />
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant='link'
                onClick={close}
                className='flex-grow sm:flex-none'
              >
                Cancel
              </Button>
              <Button type='submit' className='flex-grow sm:flex-none'>
                Deploy
              </Button>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  )
}
