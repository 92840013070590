import React from 'react'
import { DeploymentStatus } from './DeploymentStatus'

const DeploymentField = ({ children }) => (
  <p className='flex flex-row type-default'>{children}</p>
)

const DeploymentLabel = ({ children }) => (
  <label className='font-semibold'>{children}</label>
)

const DeploymentInfo = ({ build }) => {
  return (
    <>
      <DeploymentField>
        <DeploymentLabel>Deployment number:</DeploymentLabel>
        <p>{build.deploymentNumber || 'No deployment yet'}</p>
      </DeploymentField>
      <DeploymentField>
        <DeploymentLabel>Deployment status:</DeploymentLabel>
        <DeploymentStatus status={build.deploymentStatus || ''} />
      </DeploymentField>
    </>
  )
}

export default DeploymentInfo
