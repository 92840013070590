import React, { useState } from 'react'
import { useQuery } from 'react-query'
import { ViewportList } from 'react-viewport-list'
import cx from 'classnames'

import { MerryGoRound } from '@toasttab/buffet-pui-loading-indicators'

import { Button, IconButton } from '@toasttab/buffet-pui-buttons'

import { fetchLogData } from '../queries'
import { DownloadLogsButton } from '../components/DownloadButtons'
import { CardContainer } from '@toasttab/buffet-pui-card'
import { CloseIcon, FullScreenIcon } from '@toasttab/buffet-pui-icons'
import { Checkbox } from '@toasttab/buffet-pui-checkbox'
import { SimpleFullScreenModal } from './SimpleFullScreenModal'

const Convert = require('ansi-to-html')
const ansi = new Convert({
  escapeXML: true,
  stream: false, // streaming can choke and end up with terrible performance issues
  bg: '#fff',
  fg: 'rgb(37, 37, 37)'
})

const generateAnchorId = (stageName) =>
  `stage-${stageName.replaceAll(' ', '-')}`

const StageAnchor = ({ stageName, ...props }) => {
  return (
    <div className='my-8 relative' {...props}>
      {/* The "border" is rendered this way so that it shows just above the fold */}
      <div
        className='absolute w-full border-t left-0'
        style={{ top: '-1px' }}
      />
      <h2 className='type-headline-5 font-semibold pt-4'>{stageName}</h2>
    </div>
  )
}

const findStages = (lines, showLoadingIndicator) => {
  const stageBeginIndices = lines.reduce(
    (indices, line, index) =>
      line.includes(`[Pipeline] { (`) ? [...indices, index] : indices,
    []
  )

  // scan up to find the indexes that end with: [Pipeline] // stage

  let stages = stageBeginIndices.map((index) => {
    const line = lines[index]
    const stageName = line.replace(/.*Pipeline\] { \((.*)\)/, (_$0, $1) => $1)
    let breakIndex = index

    // We start looking up 2 lines
    for (let i = index - 2; i >= 0 && i > index - 10; i--) {
      const l = lines[i]
      if (l.endsWith('[Pipeline] // stage')) {
        breakIndex = i
        break
      }
    }
    return { stageName, index: breakIndex, id: generateAnchorId(stageName) }
  })

  const stagesByIndex = {}
  stages.forEach((stage) => {
    stagesByIndex[stage.index] = stage
  })

  return { stages, stagesByIndex }
}

const LogLine = ({ stage, line }) => {
  return (
    <React.Fragment>
      {stage && <StageAnchor stageName={stage.stageName} id={stage.id} />}
      <div
        // We are trusting ansi-to-html's escapeXML flag to work securely
        dangerouslySetInnerHTML={{ __html: ansi.toHtml(line) }}
      />
    </React.Fragment>
  )
}

const Logs = ({
  isLoading,
  isFullscreen,
  setFullscreen,
  containerRef,
  building,
  fullFailureText,
  lines
}) => {
  const [wrapLines, setWrapLines] = useState(false)

  const listRef = React.useRef()

  const showLoadingIndicator = (building || isLoading) && !fullFailureText

  const { stages, stagesByIndex } = findStages(lines, showLoadingIndicator)

  React.useEffect(() => {
    if (!showLoadingIndicator) {
      const stageAnchorKey = global.location.hash
      if (stageAnchorKey) {
        document.querySelector(stageAnchorKey)?.scrollIntoView()
      }
    }
  }, [isFullscreen, showLoadingIndicator])

  React.useEffect(() => {
    const onHashChange = () => {
      const stageId = location.hash.substr(1),
        stage = stages.find((stage) => stage.id === stageId)

      if (stage && listRef.current) {
        listRef.current.scrollToIndex({ index: stage.index })
      }
    }

    window.addEventListener('hashchange', onHashChange)

    return () => window.removeEventListener('hashchange', onHashChange)
  }, [stages])

  return (
    <>
      <pre
        tabIndex='0'
        className={cx('overflow-auto outline-none', {
          'w-screen': isFullscreen,
          'whitespace-normal': wrapLines
        })}
        ref={containerRef}
        style={{
          height: isFullscreen ? '100vh' : '50vh',
          maxHeight: isFullscreen ? '100vh' : '480px'
        }}
      >
        {lines.length === 0 ? (
          <></>
        ) : (
          <div className='p-4'>
            {lines.length < 10000 ? (
              lines.map((line, index) => {
                const stage = stagesByIndex[index]
                return <LogLine stage={stage} line={line} key={index} />
              })
            ) : (
              <ViewportList
                ref={listRef}
                viewportRef={containerRef}
                items={lines}
              >
                {(line, index) => {
                  const stage = stagesByIndex[index]
                  return <LogLine stage={stage} line={line} key={index} />
                }}
              </ViewportList>
            )}
          </div>
        )}
        {showLoadingIndicator && (
          <div className={cx('p-4', { 'pt-0': lines.length > 0 })}>
            <MerryGoRound />
          </div>
        )}
      </pre>
      <div className='absolute top-1 right-1 rounded-full md:top-2 md:right-2 bg-white'>
        {!isFullscreen && (
          <IconButton
            icon={<FullScreenIcon aria-label='Open full screen logs' />}
            onClick={() => setFullscreen(true)}
          />
        )}
        {isFullscreen && (
          <IconButton
            icon={<CloseIcon aria-label='Close full screen logs' />}
            onClick={() => setFullscreen(false)}
          />
        )}
      </div>
      <div className='absolute bottom-1 right-1 md:bottom-2 md:right-2 bg-white'>
        <Checkbox
          checked={wrapLines}
          label={'Wrap lines'}
          onChange={() => setWrapLines(!wrapLines)}
        />
      </div>
    </>
  )
}

const LogFollowButton = ({ follow, setFollow }) => {
  return (
    <Button
      onClick={() => {
        setFollow(!follow)
      }}
    >
      {follow ? 'Unfollow logs' : 'Follow logs'}
    </Button>
  )
}

const LogsPanel = ({ build, repositoryId, hasRunStatus, isHidden }) => {
  const [fetchInterval, setFetchInterval] = useState(2000)
  const containerRef = React.useRef()
  const building = build.finishedAt === null
  const [follow, setFollow] = React.useState(building)
  const [isFullscreen, setFullscreen] = React.useState(false)

  React.useLayoutEffect(() => {
    if (containerRef.current && follow) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight
    }
  })

  React.useEffect(() => {
    // Stop following the logs when build finishes
    if (!building && follow) {
      setFollow(false)
    }
  }, [building, follow])

  const { id: buildId, repository: repoName, status } = build
  const failureStatus = build.failedStatusMessage
  const failureStackTrace = build.failedStatusStackTrace
  const fullFailureText =
    failureStatus && failureStackTrace
      ? `${failureStatus}\n\nStack trace below:\n\n${failureStackTrace}`
      : null

  const { logDataQueryId, logDataQuery, logDataConfig } = {
    logDataQueryId: ['fetchLogData', { repoName, buildId }],
    logDataQuery: fetchLogData,
    logDataConfig: {
      refetchInterval:
        status === 'PASSED' || status === 'FAILED' || status === 'ABORTED'
          ? null
          : fetchInterval
    }
  }

  const onRequestClose = React.useCallback(() => {
    setFullscreen(false)
  }, [setFullscreen])

  const { data, error, isLoading } = useQuery(
    logDataQueryId,
    logDataQuery,
    logDataConfig
  )

  if (error && error.status === 404) {
    return (
      <p>Something is not right. We don't seem to have this log anymore.</p>
    )
  }

  if (error) {
    return <p>Error while fetching logs: {JSON.stringify(error)}</p>
  }

  function splitLogs(data) {
    if (data !== undefined && data !== null) {
      // Logs have \n and \r mixed in.  Splitting based on string is far more performant on large strings than regex
      // hence this approach
      return data
        .split('\r\n')
        .flatMap((ln) => ln.split('\n').flatMap((l) => l.split('\r')))
    }
    return []
  }

  const lines = isHidden
    ? []
    : !!fullFailureText
    ? splitLogs(fullFailureText)
    : !!data
    ? splitLogs(data)
    : []

  return (
    <>
      {isFullscreen ? (
        <SimpleFullScreenModal onRequestClose={onRequestClose}>
          <Logs
            isLoading={isLoading}
            isFullscreen={isFullscreen}
            setFullscreen={setFullscreen}
            containerRef={containerRef}
            building={building}
            fullFailureText={fullFailureText}
            lines={lines}
          />
        </SimpleFullScreenModal>
      ) : (
        <CardContainer
          className='w-auto mb-4 relative'
          style={{
            minHeight: '48px'
          }}
          noPadding
        >
          <Logs
            isLoading={isLoading}
            isFullscreen={isFullscreen}
            setFullscreen={setFullscreen}
            containerRef={containerRef}
            building={building}
            fullFailureText={fullFailureText}
            lines={lines}
          />
        </CardContainer>
      )}
      {error || !build.finishedAt ? (
        hasRunStatus ? (
          <LogFollowButton follow={follow} setFollow={setFollow} />
        ) : (
          <p>No logs for this build.</p>
        )
      ) : (
        <DownloadLogsButton build={build} />
      )}
    </>
  )
}

export default LogsPanel
