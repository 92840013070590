import * as React from 'react'
import { Formik, Form } from 'formik'

import {
  DeployTypeField,
  DeploymentNumberField,
  WorkerNameField
} from './ToastwebDeployFormFields'
import { Modal } from '@toasttab/buffet-pui-modal'
import { Button } from '@toasttab/buffet-pui-buttons'

const toastwebDeployUrls = {
  all: 'https://jenkins.build.int.toasttab.com/job/toastweb/job/deploy/job/preproduction-toastweb-all/parambuild/',
  web: 'https://jenkins.build.int.toasttab.com/job/toastweb/job/deploy/job/preproduction-toastweb-web/parambuild/',
  nonweb:
    'https://jenkins.build.int.toasttab.com/job/toastweb/job/deploy/job/preproduction-toastweb-nonweb/parambuild/',
  worker:
    'https://jenkins.build.int.toasttab.com/job/toastweb/job/deploy/job/preproduction-toastweb-worker/parambuild/'
}

export const ToastwebDeployForm = ({ isDeployFormOpen, deployInfo, close }) => {
  // This URL is going to change based on what type of deploy they have selected
  // worker: https://jenkins.build.int.toasttab.com/job/toastweb/job/deploy/job/preproduction-toastweb-worker/parambuild/
  // all: https://jenkins.build.int.toasttab.com/job/toastweb/job/deploy/job/preproduction-toastweb-all/parambuild/
  // web: https://jenkins.build.int.toasttab.com/job/toastweb/job/deploy/job/preproduction-toastweb-web/parambuild/
  // nonweb: https://jenkins.build.int.toasttab.com/job/toastweb/job/deploy/job/preproduction-toastweb-nonweb/parambuild/
  const submitDeploy = (values) => {
    close()
    let toastwebDeployUrl = toastwebDeployUrls[values.deployType]
    if (!toastwebDeployUrl) {
      console.error(`Could not find deploy url for type ${values.deployType}`)
      return
    }
    let completeDeployValues = {
      WORKER_TYPE: values.workerName || 'jobWorker',
      DEPLOYMENT: values.deploymentNumber || '0',
      BOB_BUILT: deployInfo.BOB_BUILT,
      IMAGE_URI: deployInfo.IMAGE_URI,
      RELEASE_BRANCH: deployInfo.RELEASE_BRANCH,
      BUILD_NUMBER: deployInfo.BUILD_NUMBER,
      BRANCH_NAME: deployInfo.BRANCH_NAME
    }
    let fullToastwebDeployUrl =
      toastwebDeployUrl +
      '?' +
      new URLSearchParams(completeDeployValues).toString()
    window.open(fullToastwebDeployUrl, '_blank')
  }

  // Set showModal to false
  return (
    <Modal isOpen={isDeployFormOpen}>
      <Modal.Header>Deploy Toastweb</Modal.Header>
      <Formik
        initialValues={{
          deployType: 'all',
          deploymentNumber: 0,
          workerName: ''
        }}
        onSubmit={submitDeploy}
      >
        {(props) => (
          <Form id='deploy-toastweb' autoComplete='false'>
            <Modal.Body>
              <div className='flex flex-col space-y-4 pb-1'>
                <DeployTypeField />
                <DeploymentNumberField />
                <WorkerNameField
                  isDisabled={props.values.deployType !== 'worker'}
                />
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant='link'
                onClick={close}
                className='flex-grow sm:flex-none'
              >
                Cancel
              </Button>
              <Button type='submit' className='flex-grow sm:flex-none'>
                Deploy
              </Button>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  )
}
