import React from 'react'
import { Redirect, useParams } from 'react-router-dom'

const LegacyBuildRedirect = () => {
  const { buildId, timestamp, repoName } = useParams()

  return <Redirect to={`/${repoName}/builds/${buildId}`}></Redirect>
}

export default LegacyBuildRedirect
