import React from 'react'
import { Button } from '@toasttab/buffet-pui-buttons'
import {
  AlertModal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from '@toasttab/buffet-pui-modal'

const TerminateConfirmationModal = ({ isOpen, onConfirm, onClose }) => (
  <AlertModal
    isOpen={isOpen}
    size='sm'
    testId={'terminate-build-confirmation-modal'}
  >
    <ModalHeader>Terminate build?</ModalHeader>
    <ModalBody>This will immediately cancel the current build</ModalBody>
    <ModalFooter>
      <Button variant={'link'} onClick={onClose}>
        Cancel
      </Button>
      <Button variant={'primary'} onClick={onConfirm}>
        Terminate
      </Button>
    </ModalFooter>
  </AlertModal>
)

export default TerminateConfirmationModal
