import { React, useState } from 'react'
import DateTime from 'luxon/src/datetime'
import Duration from 'luxon/src/duration'
import cx from 'classnames'
import { AutorenewIcon } from '@toasttab/buffet-pui-icons'
import { Step } from '@toasttab/buffet-pui-stepper'
import { ScreenSize, useScreenSize } from '@toasttab/use-screen-size'

const getTime = (state) => state.happenedAt || state.startedAt

const sidePadding = () => 'px-4'

const GroupedStageDetails = ({ stage, previousState, wrapSteps }) => {
  const borderColor = stage.children.some((e) => e.status === 'FAILED')
    ? 'border-red-100'
    : 'border-primary-75'
  //Maybe later this becomes a prop that is tied into expanding/contracting the parallel block
  //to be done by someone smarter than me
  const expanded = true
  if (expanded) {
    return (
      <div>
        <div
          className={cx('border -mt-1 ml-1 mr-1 pt-1 pl-1 pr-1', borderColor)}
        >
          {stage.children.map((child, i) => (
            <li
              key={getTime(child)}
              style={{
                width: wrapSteps ? undefined : '144px',
                minWidth: wrapSteps ? '128px' : undefined
              }}
              className='pb-4'
            >
              <StageDetails
                stage={child}
                previousState={previousState}
                isFirst={i !== 0}
                isLast={i !== 0}
              />
            </li>
          ))}
        </div>
        <span className='type-caption font-normal text-secondary flex justify-center text-center whitespace-nowrap pt-1'>
          {Duration.fromMillis(stage.endedAt - stage.startedAt).toFormat(
            "m 'min' s 'sec'"
          )}
        </span>
      </div>
    )
  } else {
    return (
      <div className={cx('border -mt-1 ml-1 mr-1 pt-1 pl-1 pr-1', borderColor)}>
        <StageDetails
          stage={stage}
          previousState={previousState}
          isFirst={false}
          isLast={false}
        />
      </div>
    )
  }
}

const StageDetails = ({ stage, previousState, ...props }) => (
  <a
    href={`#stage-${stage.name.replaceAll(' ', '-')}`}
    className='outline-none focus-visible:shadow-focus'
  >
    <Step
      title={
        <div
          className={cx(
            sidePadding(props),
            stage.status !== 'FAILED' && 'text-link'
          )}
        >
          {stage.name}
        </div>
      }
      size='sm'
      description={
        <div className={sidePadding(props)}>
          {stage.endedAt ? (
            Duration.fromMillis(stage.endedAt - stage.startedAt).toFormat(
              "m 'min' s 'sec'"
            )
          ) : (
            <>
              <AutorenewIcon
                size='xs'
                className='text-secondary animate-spin mr-1'
              />
              {Duration.fromMillis(Date.now() - stage.startedAt).toFormat(
                "m 'min' s 'sec'"
              )}
            </>
          )}
        </div>
      }
      circleContent='icon'
      labelOn='below'
      state={
        stage.status === 'PASSED'
          ? 'complete'
          : stage.status === 'FAILED'
          ? 'error'
          : 'active'
      }
      {...props}
    />
  </a>
)

const StatusDetails = ({ status, nextStateTime, ...props }) => {
  const timeMarker =
    status.status === 'QUEUED'
      ? Duration.fromMillis(
          (nextStateTime || Date.now()) - status.happenedAt
        ).toFormat("m 'min' s 'sec'")
      : status.status === 'CREATED' || nextStateTime === null
      ? DateTime.fromMillis(status.happenedAt).toFormat('yyyy LLL dd HH:mm')
      : Duration.fromMillis(nextStateTime - status.happenedAt).toFormat(
          "m 'min' s 'sec'"
        )

  return (
    <Step
      title={
        <div className={sidePadding(props)}>{status.status.toLowerCase()}</div>
      }
      description={<div className={sidePadding(props)}>{timeMarker}</div>}
      circleContent='icon'
      labelOn='below'
      size='sm'
      state={
        status.status === 'FAILED'
          ? 'inactive'
          : status.status === 'PASSED'
          ? 'complete'
          : nextStateTime === null
          ? 'active'
          : 'complete'
      }
      {...props}
    />
  )
}

const BuildStatuses = ({ stages, statuses }) => {
  const states = [...stages, ...statuses]
    .filter((s) => s.startedAt || s.status !== 'RUNNING')
    .sort((s1, s2) => getTime(s1) - getTime(s2))

  const screenSize = useScreenSize()

  const wrapSteps = screenSize > ScreenSize.MD

  return (
    <>
      <ul
        className={cx(
          'flex flex-row pt-4',
          wrapSteps
            ? 'flex-wrap'
            : 'overflow-x-auto -mx-4 md:-mx-6 px-0 md:px-2'
        )}
      >
        {states.map((s, index) => {
          const nextStateTime =
            index < states.length - 1 ? getTime(states[index + 1]) : null

          const previousStage = index > 0 ? states[index - 1] : undefined
          return (
            <li
              key={getTime(s)}
              style={{
                width: wrapSteps ? undefined : '144px',
                minWidth: wrapSteps ? '128px' : undefined
              }}
              className='pb-4'
            >
              {s.startedAt ? (
                s.children === undefined ? (
                  <StageDetails
                    key={getTime(s)}
                    stage={s}
                    previousStage={previousStage}
                    isFirst={index === 0}
                    isLast={index === states.length - 1}
                  />
                ) : (
                  <GroupedStageDetails
                    key={getTime(s)}
                    stage={s}
                    previousStage={previousStage}
                    isFirst={index === 0}
                    isLast={index === states.length - 1}
                    wrapSteps={wrapSteps}
                  />
                )
              ) : (
                <StatusDetails
                  key={getTime(s)}
                  nextStateTime={nextStateTime}
                  status={s}
                  previousStatus={previousStage}
                  isFirst={index === 0}
                  isLast={index === states.length - 1}
                />
              )}
            </li>
          )
        })}
      </ul>
    </>
  )
}

export default BuildStatuses
