import React, { useState } from 'react'

import { Button } from '@toasttab/buffet-pui-buttons'

import { triggerDeploy } from '../queries'
import { Link } from 'react-router-dom'
import { Alert } from '@toasttab/buffet-pui-alerts'
import { MerryGoRound } from '@toasttab/buffet-pui-loading-indicators'
import { ToastwebDeployForm } from './ToastwebDeployForm'
import { AdhocDeployForm } from './AdhocDeployForm'

export const getServiceNameFromImage = (imageName, isAdhoc = false) => {
  if (imageName == null) {
    console.error('Image name is null')
    return ''
  }
  const serviceNameRegex = isAdhoc ? /:(.*)-/ : /\/([^\/]*):[^:]*$/

  const matches = imageName.match(serviceNameRegex)
  if (matches == null || matches.count < 2) {
    console.error('Could not get service name from image name')
    // An empty string for the service name should throw an error when the call to dobby is made
    return ''
  }

  return matches[1].replace(/^toast-/, '')
}

const triggerDeployJob = (
  {
    repoId,
    uriOfImageToDeploy,
    serviceName,
    revision,
    repoName,
    isAdhoc,
    branchName,
    shouldActivateDeploy,
    buildId,
    environment,
    timeToLive = 1,
    doDisableButton = true
  },
  setCallingDobby,
  setDoShowLink,
  setLinkNumber,
  setIsError
) => {
  setCallingDobby(true)
  const serviceNameOfImage = getServiceNameFromImage(
    uriOfImageToDeploy,
    isAdhoc
  )
  let body = JSON.stringify({
    repo_id: repoId,
    docker_image_uri: uriOfImageToDeploy,
    service_name: serviceNameOfImage,
    revision: revision,
    repo_name: repoName,
    branch_name: branchName,
    should_activate_deploy: shouldActivateDeploy,
    build_id: buildId,
    environment: environment,
    ttl: `${timeToLive * 24}h`
  })
  triggerDeploy(body)
    .then((data) => {
      setLinkNumber(data)
      setDoShowLink(true)
      setCallingDobby(false)
    })
    .catch((error) => {
      setIsError(true)
      setCallingDobby(false)
      console.log(error.message)
    })
}

export const DeployButton = ({
  repoId,
  serviceName,
  revision,
  repoName,
  dockerImageUris,
  isAdhoc,
  branchName,
  shouldActivateDeploy,
  buildId
}) => {
  // Set the revision to 9999 assuming there isn't one already which means this is an adhoc
  if (isAdhoc) {
    revision = 9999
  }
  const canDeploy = dockerImageUris && dockerImageUris.length >= 1
  const dockerImageUri = !!dockerImageUris ? dockerImageUris[0] : null
  const [callingDobby, setCallingDobby] = useState(false)
  const [isError, setIsError] = useState(false)
  const [doShowLink, setDoShowLink] = useState(false)
  const [linkNumber, setLinkNumber] = useState(0)
  const [showDeployForm, setShow] = useState(false)

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  const triggerDeployFromForm = (imageUri, doActivate, timeToLive) => {
    triggerDeployJob(
      {
        repoId: repoId,
        uriOfImageToDeploy: imageUri,
        serviceName: serviceName,
        revision: revision,
        repoName: repoName,
        isAdhoc: isAdhoc,
        branchName: branchName,
        shouldActivateDeploy: doActivate,
        buildId: buildId,
        environment: targetEnvironment,
        timeToLive: timeToLive,
        doDisableButton: false
      },
      setCallingDobby,
      setDoShowLink,
      setLinkNumber,
      setIsError
    )
  }

  // Toastweb deploys are handled through Jenkins, so this helps us link to and prefill a form for our users
  const isToastweb = repoName === 'toastweb'
  const isAnalytics = repoName === 'toast-analytics'
  const targetEnvironment = isAnalytics
    ? 'PREPRODUCTION_ANALYTICS'
    : 'PREPRODUCTION'
  // The branch name should be empty if this is a non-adhoc build
  const buildBranchName = isAdhoc ? branchName : ''
  const toastwebParams = { BOB_BUILT: true, BRANCH_NAME: buildBranchName }
  // The build number should always be the last part of the uri, so this split logic should pull the build number correctly
  const toastwebImageUriParts = !!dockerImageUri
    ? dockerImageUri.split('-')
    : ''
  const toastwebBuildNumber = !!toastwebImageUriParts
    ? toastwebImageUriParts[toastwebImageUriParts.length - 1]
    : 'Could not find build number'
  toastwebParams.RELEASE_BRANCH = isAdhoc ? 'adhoc' : branchName
  toastwebParams.IMAGE_URI = isAdhoc ? dockerImageUri : ''
  toastwebParams.BUILD_NUMBER = isAdhoc
    ? 'PLEASE CHOOSE A NUMBER'
    : toastwebBuildNumber

  if (callingDobby) {
    return <MerryGoRound />
  }
  return (
    <>
      {canDeploy ? (
        <>
          {isToastweb ? (
            <>
              <Button onClick={handleShow}>
                {isAdhoc
                  ? 'Deploy toastweb adhoc to preproduction'
                  : 'Deploy toastweb to preproduction'}
              </Button>
              <ToastwebDeployForm
                isDeployFormOpen={showDeployForm}
                deployInfo={toastwebParams}
                close={handleClose}
              />
            </>
          ) : (
            <>
              <Button onClick={handleShow}>
                {isAdhoc
                  ? `Deploy adhoc to ${targetEnvironment
                      .toLowerCase()
                      .replace('_', ' ')}`
                  : `Deploy to ${targetEnvironment
                      .toLowerCase()
                      .replace('_', ' ')}`}
              </Button>
              <AdhocDeployForm
                isDeployFormOpen={showDeployForm}
                imageUris={dockerImageUris}
                activate={shouldActivateDeploy}
                isAdhoc={isAdhoc}
                deployFunction={triggerDeployFromForm}
                close={handleClose}
              />
              <></>
              {doShowLink ? (
                <>
                  <Link
                    to={`/${repoName}/actions/${linkNumber}`}
                    className='ml-3'
                  >
                    Deployment action {linkNumber}
                  </Link>
                </>
              ) : isError ? (
                <Alert variant='error' className='ml-3'>
                  Error creating deploy action
                </Alert>
              ) : (
                <></>
              )}
            </>
          )}
        </>
      ) : (
        <></>
      )}
    </>
  )
}
