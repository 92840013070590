import React from 'react'
import { AccordionItem } from '@toasttab/buffet-pui-accordion'

import { useQuery } from 'react-query'
import { getTestData } from '../queries'
import { MetricSummaryCard } from '@toasttab/buffet-pui-metric-summary-card'
import { CardContainer } from '@toasttab/buffet-pui-card'
import { MerryGoRound } from '@toasttab/buffet-pui-loading-indicators'

import cx from 'classnames'

const Message = (props) => {
  const contents = props.data?.error?.body || ''
  return (
    <pre
      className={cx(
        'px-7 -mx-7 md:px-9 md:-mx-9 xxl:px-12 xxl:-mx-12 overflow-x-auto', // overflow, espcially on narrow screens
        'text-left type-subhead font-mono' // format stack traces nicely
      )}
    >
      {contents}
    </pre>
  )
}

const TestPanel = ({ build, lastPassingMainBranchId }) => {
  const { id: buildId, repository: repoName } = build
  const { query, config } = {
    query: getTestData,
    config: {
      retry: (_, error) => {
        if (error && error.status === 403) {
          return false
        }
        return true
      }
    }
  }
  const { data, error, isLoading } = useQuery(
    [repoName, buildId],
    query,
    config
  )

  const {
    data: previousMainBranchData,
    isLoading: isPreviousMainBranchIsLoading
  } = useQuery([repoName, lastPassingMainBranchId], query, config)

  function getBadgeColor(input, invert = false) {
    if (input === 0) return 'gray'
    if (invert === true) {
      if (input < 0) return 'success'
      if (input > 0) return 'error'
    } else {
      if (input < 0) return 'error'
      if (input > 0) return 'success'
    }
  }

  function getBadgeIcon(input) {
    if (input === 0) return null
    if (input < 0) return 'decrease'
    if (input > 0) return 'increase'
  }

  function getFullTestName(test) {
    return test.classname + '.' + test.name
  }
  function getTestText(test) {
    return getFullTestName(test) + ' - ' + test.properties.time + 's'
  }
  function handleFailureClick(e) {
    const sel = window.getSelection()
    if (sel.type === 'Range' && e.currentTarget.contains(sel.focusNode)) {
      e.stopPropagation()
    }
  }

  const testOverview = () => {
    if (data !== undefined && previousMainBranchData !== undefined) {
      return (
        <>
          <div
            className={cx(
              'mb-4 xxl:mb-6 gap-4 xxl:gap-6',
              'px-4 md:px-0',
              'grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 sm:auto-cols-fr'
            )}
          >
            <MetricSummaryCard
              label='Total tests'
              value={data.total}
              difference={data.total - previousMainBranchData.total}
              badgeColor={getBadgeColor(
                data.total - previousMainBranchData.total
              )}
              badgeIcon={getBadgeIcon(
                data.total - previousMainBranchData.total
              )}
              helperText='more than main branch'
              withOuterCard
            />
            <MetricSummaryCard
              label='Passed tests'
              value={data.passedTests}
              difference={data.passedTests - previousMainBranchData.passedTests}
              badgeColor={getBadgeColor(
                data.passedTests - previousMainBranchData.passedTests
              )}
              badgeIcon={getBadgeIcon(
                data.passedTests - previousMainBranchData.passedTests
              )}
              helperText='more than main branch'
              withOuterCard
            />
            <MetricSummaryCard
              label='Failed tests'
              value={data.failedTests}
              difference={data.failedTests - previousMainBranchData.failedTests}
              badgeColor={getBadgeColor(
                data.failedTests - previousMainBranchData.failedTests,
                true
              )}
              badgeIcon={getBadgeIcon(
                data.failedTests - previousMainBranchData.failedTests
              )}
              helperText='more than main branch'
              withOuterCard
            />
          </div>
        </>
      )
    }
    return <></>
  }

  return (
    <>
      {testOverview()}
      {(isLoading || isPreviousMainBranchIsLoading) && (
        <div className={cx('p-4', { 'pt-0': true })}>
          <MerryGoRound />
        </div>
      )}
      {!isLoading && !error && data !== undefined && data.tests !== null && (
        <div>
          {data.failedTests && data.failedTests > 0 ? (
            <CardContainer className='mb-4 xxl:mb-6'>
              <h2>Failing tests</h2>
              <br />
              <ul className='break-all'>
                {data.tests
                  .filter((e, i, a) => {
                    return (
                      e.status.toUpperCase() === 'FAILED' ||
                      e.status.toUpperCase() === 'ERROR'
                    )
                  })
                  .sort((a, b) => {
                    return getFullTestName(a).localeCompare(getFullTestName(b))
                  })
                  .map((test, i) => {
                    return (
                      <li key={i} className="tdp--failed-test" onClickCapture={handleFailureClick}>
                        <AccordionItem
                          id={test.name + i}
                          title={getTestText(test)}
                        >
                          <Message data={test} />
                        </AccordionItem>
                      </li>
                    )
                  })}
              </ul>
            </CardContainer>
          ) : (
            <></>
          )}
          <CardContainer className='mb-4 xxl:mb-6'>
            <h2>Passing tests</h2>
            <br />
            <ul className='break-all'>
              {data.tests
                .filter((e, i, a) => {
                  return e.status.toUpperCase() === 'PASSED'
                })
                .sort((a, b) => {
                  return (a.classname + '.' + a.name).localeCompare(
                    b.classname + '.' + b.name
                  )
                })
                .map((test, i) => {
                  return <li key={i} className='py-1'>{getTestText(test)}</li>
                })}
            </ul>
          </CardContainer>
        </div>
      )}
    </>
  )
}
export default TestPanel
